<!--
 * @Author: Su
 * @Date: 2020-11-24 14:56:55
 * @LastEditTime: 2020-11-28 19:56:09
 * @LastEditors: LazyQ
-->
<style lang="less" scoped>
.page-info {
  min-width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: space-around;
  background-color: #f7f8fa;
  .info-view {
    display: flex;
    flex-direction: column;
    .header-info {
      width: 100%;
      height: 58px;
    }
    .content-info {
      min-width: 1200px;
      max-width: 1200px;
      height: calc(100% - 58px);
      background-color: white;
      padding: 0 120px;
      box-sizing: border-box;
      .title {
        color: #333333;
        font-size: 24px;
        font-weight: bold;
        margin-top: 60px;
      }
      .content {
        color: #666666;
        font-size: 18px;
        margin: 30px 0;
        line-height: 40px;
      }
    }
  }
}
</style>
<template>
  <div class="page-info">
    <div class="info-view">
      <questionnaire-header
        class="header-info"
        :hisPage="pageName"
      ></questionnaire-header>
      <div class="content-info">
        <div class="title">关于我们</div>
        <p class="content">“蚂蚁调研网”社区是一个在线任务参与平台，任务类型以在线问卷为主。用户可通过电脑、手机、平板等各类终端访问网站、APP，参与任务、回答问卷、获取奖励并兑换各类奖品。自2021年以来，数百万来自不同地域、不同年龄阶段、不同文化背景的会员用户，在蚂蚁调研网社区参与任务，赚取奖励。“蚂蚁调研网”社区致力于提供精准、简单、奖励丰厚的任务给会员，并将用户的声音传递给企业。</p>
      </div>
    </div>
  </div>
</template>

<script>
import QuestionnaireHeader from "@/components/QuestionnaireHeader.vue";

export default {
  name: "about",
  components: {
    QuestionnaireHeader,
  },
  data() {
    return {
      pageName: "关于我们",
      hintTitle: "",
      
    };
  },
  created() {
    
  },
  methods: {
    
  },
};
</script>
